<template>
  <div class="article-box">
    <el-table :data="articles" style="width: 100%" v-loading="loading">
      <el-table-column prop="serial" label="序号" width="150" align="center">
        <template slot-scope="scope">
          {{ (currpage - 1) * pagesize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">
          {{ scope.row.Title.slice(0,40) }} <span v-if="scope.row.Title.length>40">...</span>
          </template>
      </el-table-column>
      <el-table-column prop="Author" label="作者" align="center">
      </el-table-column>
      <el-table-column sortable prop="InsertTime" label="发布时间" align="center">
      </el-table-column>
      <el-table-column sortable prop="UpdateTime" label="审核时间" align="center">
      </el-table-column>
      <el-table-column prop="examine" label="审核人" align="center">
        <!-- <template>
          {{ admin }}
        </template> -->
      </el-table-column>
      <!-- <el-table-column
        label="标签"
        prop="Tags[0].Tags"
        width="180">
      </el-table-column> -->
      <el-table-column label="操作" fixed="right" width="160" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="danger"
            plain
            @click="handleEdit(scope.$index, scope.row)">冻结</el-button> -->
          <el-button size="mini" type="primary" @click="handleDelete(scope.$index, scope.row)">解冻</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    articleList,
    thaw
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        articles: [], // 文章列表
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0, // 总条数
        loading: false, //加载框
        admin: '管理员'
      }
    },
    mounted() {
      this.ArticleList(1) // 初始化，获取文章列表
    },
    methods: {
      ArticleList(currpage) { // 文章列表
        this.loading = true
        const data = {
          page: currpage, // 页数
          limit: this.pagesize, // 总数
          status: 3 // 0待审核 1已审核 2已拒绝 3已冻结 10全部
        }
        articleList(data).then(res => {
          this.articles = res.data.List // 已冻结文章列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      UpdateTimes(row, column) { // 审核时间过滤
        return row.UpdateTime.slice(0, 16)
      },
      InsertTime(row, column) { // 发布时间过滤
        return row.InsertTime.slice(0, 16)
      },
      handleDelete(index, row) { // 文章解冻
        const data = {
          ID: row.ID,
          status: 1
        } // 文章状态 ( 1解冻 3冻结 )
        thaw(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '冻结成功',
              type: 'success'
            })
            this.ArticleList(this.currpage)
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.ArticleList(val)
        this.currpage = val
      }
    }
  }
</script>
<style lang="scss" scoped>
  .article-box {
    width: 100%;

    .el-pagination {
      margin-top: 20px;
    }

    .quill-editor {
      height: 300px;
      margin-bottom: 20px;
    }
  }
</style>
